<template>
  <div class="galaxy-frames" v-if="pageInfo[$route.params.frame]">
    <div class="content">
      <router-link
        class="button button_pink mb-8 d-inline-flex px-8"
        :to="{
          name: 'TimeHobl',
          query:
            user && Object.keys(user).length
              ? {}
              : { access: '3a7dbae2ea7e84704761e4aba89351a6' },
        }"
      >
        <span>Назад</span>
      </router-link>
      <div
        class="galaxy-frames__title"
        v-html="pageInfo[$route.params.frame].title"
      ></div>
      <div
        class="galaxy-frames__description"
        v-html="pageInfo[$route.params.frame].description"
      ></div>
      <div class="galaxy-frames__container">
        <iframe
          class="sharing"
          v-if="$route.params.frame === 'share'"
          src="https://padlet.com/embed/ybmnja88ztmfh5c0"
          frameborder="0"
          allow="camera;microphone;geolocation"
        ></iframe>
        <div
          class="find_word"
          v-if="$route.params.frame === 'find_word'"
          id="otp_wgt_v7qtxcvetpvry"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "GalaxyFrame",
  metaInfo() {
    if (this.pageInfo[this.$route.params.frame]) {
      return { title: this.pageInfo[this.$route.params.frame].title };
    }
  },
  data: () => ({
    pageInfo: {
      share: {
        title: "Делитесь ссылками",
        description:
          "Мы создали общее поле для обмена полезной информации с коллегами. Делитесь ссылками на актуальные ресурсы!",
      },
      find_word: {
        title: "Найдите слова",
        description:
          "Небольшая простая игра для того, чтобы отвлечься от трансляции в перерывах. Найдите простые, но важные слова, которые мы зашифровали для Вас!",
      },
    },
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    findWord(w, d, n, s, rp) {
      let otp_wjs_dt = new Date().getTime();
      w[n] = w[n] || [];
      rp = {};
      w[n].push(function () {
        otp_render_widget(
          d.getElementById("otp_wgt_v7qtxcvetpvry"),
          "onlinetestpad.com",
          "v7qtxcvetpvry",
          rp
        );
      });
      s = d.createElement("script");
      s.type = "text/javascript";
      s.src = "//onlinetestpad.com/js/widget.js?" + otp_wjs_dt;
      s.async = true;
      d.getElementsByTagName("head")[0].appendChild(s);
    },
  },
  mounted() {
    if (
      !this.pageInfo[this.$route.params.frame] ||
      this.pageInfo[this.$route.params.frame].closed
    ) {
      this.$router.push({ name: "Galaxy" });
    }
    document.querySelector(
      "#app"
    ).style.backgroundImage = `url(https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/breztri/frames_back.jpg)`;
    if (document.querySelector("#otp_wgt_v7qtxcvetpvry")) {
      this.findWord(window, window.document, "otp_widget_callbacks");
    }
    if (document.querySelector(".digital_quiz")) {
      this.digitalQuiz();
    }
    if (document.querySelector(".callback")) {
      this.callbackIframe(window, document, "script", "smcx-sdk");
    }
  },
  beforeDestroy() {
    document.querySelector("#app").style.backgroundImage = "";
  },
};
</script>

<style lang="scss" scoped>
.galaxy-frames {
  padding: 64px 0 0;

  @media screen and (max-width: 1220px) {
  }

  @media screen and (max-width: 767px) {
    padding-top: 40px;
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1F1F1F;

    @media screen and (max-width: 1220px) {
    }

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__description {
    margin: 64px 0;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #1F1F1F;

    @media screen and (max-width: 1220px) {
      margin: 40px 0;
    }

    @media screen and (max-width: 767px) {
      margin: 24px 0 40px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__container {
    display: flex;
  }

  iframe {
    margin: 0 auto;

    @media screen and (max-width: 1220px) {
      width: 100%;
      // height: 500px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      // height: 300px;
    }
  }
}

.maths {
  margin: 0 auto;

  width: 795px;
  height: 690px;
  @media screen and (max-width: 1220px) {
    width: 100%;
    height: 590px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 500px;
  }
}

.sharing {
  margin: 0 auto;

  width: 1000px;
  height: 600px;
  @media screen and (max-width: 1220px) {
    width: 100%;
    height: 500px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 450px;
  }
}

.find_word {
  margin: 0 auto;

  width: 900px;
  height: 675px;
  @media screen and (max-width: 1220px) {
    width: 100%;
    height: 675px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 825px;
  }
}

.puzzle {
  margin: 0 auto;

  width: 900px;
  height: 550px;
  @media screen and (max-width: 1220px) {
    width: 100%;
    height: 550px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 500px;
  }
}

.galaxy_sync {
  margin: 0 auto;

  width: 800px;
  height: 800px;
  @media screen and (max-width: 1220px) {
    width: 100%;
    height: 700px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 700px;
  }
}

.digital_quiz {
  margin: 0 auto;

  width: 1000px;
  height: 600px;
  @media screen and (max-width: 1220px) {
    width: 100%;
    height: 550px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 500px;
  }
}

.callback {
  margin: 0 auto;

  width: 750px;
  height: auto;
  @media screen and (max-width: 1220px) {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    height: auto;
  }
}
</style>